{
  "participants": "{{count}} people",
  "processNumber": "P.O. number",
  "tabs": {
    "externalCosts": "External costs",
    "organizers": "Organizers",
    "overview": "Overview",
    "registration": "Registration",
    "requests": "Requests",
    "surveys": "Surveys",
    "tasks": "Tasks"
  },
  "steps": {
    "planning": {
      "label": "Planning",
      "addSomething": {
        "heading": "Add something"
      },
      "pendingRequests": {
        "heading": "Pending requests"
      },
      "summary": {
        "heading": "Booking summary"
      },
      "form": {
        "submit": "Next ->",
        "workingOnIt": "We are working on it...",
        "notChargedYet": "You will not be charged yet at this step",
        "workingOnItInfo": "Our team is currently working on your proposal, we'll notify you by email as soon as it's ready!"
      }
    },
    "commitment": {
      "types": {
        "APPROVAL_REQUEST": "Approval",
        "QUOTE_SIGNATURE": "Quote",
        "CREDIT_CARD": "Payment"
      },
      "creditCard": {
        "form": {
          "cardDetails": {
            "label": "Card details"
          }
        }
      },
      "quote": {
        "form": {
          "title": "Sign the quote",
          "currentUserSelectLabel": {
            "label": "I will sign the quote myself"
          },
          "select": {
            "label": "Send the quote to an existing user"
          },
          "email": {
            "label": "Send the quote using an email address"
          },
          "requireQuote": {
            "label": "This booking is submitted to an approval",
            "description": "Your booking will progress once the quote has been signed:"
          },
          "sendQuote": "Send the quote",
          "signQuote": "Sign the quote",
          "awaitingQuote": "Awaiting quote signing…"
        },
        "toasts": {
          "error": "An error occurred while generating the quote",
          "success": "Quote sent"
        }
      },
      "form": {
        "processNumber": {
          "helperText": "If applicable",
          "placeholder": "PO12345"
        },
        "sentOn": "sent on {{date}}"
      }
    },
    "confirmation": {
      "label": "Confirmation",
      "bookingApproved": "This booking has been approved",
      "awaitingConfirmation": "Awaiting availability confirmation…",
      "notChargedYet": "You will only be charged if availability is confirmed",
      "checkingAvailability": {
        "heading": "Confirmation availability",
        "description": "We are currently awaiting confirmation of availability from our partners.\n\nIt usually takes between <strong>24 to 48 hours</strong> and you will be notified by email with all the necessary information as soon as the availability is confirmed. Hold on tight, you're almost there!"
      }
    },
    "waitingEvent": {
      "tabLabel": "Waiting event",
      "heading": "Your event is confirmed  🎉",
      "jurneeComment": "Comment from the Jurnee team:",
      "button": "Waiting for the event…",
      "reminder": {
        "allSet": "You’re all set, while you wait for your event, just remember to:",
        "ensureParticipants": "Ensure participants have event details to maximize their experience",
        "sendReminders": "Send reminders 3 days before and on the event's eve",
        "askParticipants": "For smaller group events, ask participants to confirm their attendance",
        "afterEvent": "After the event, you will be able to send a quick survey to know what everyone thought 😉"
      }
    },
    "finished": {
      "tabLabel": "Finished",
      "reviews": {
        "heading": "Reviews"
      },
      "label": "Gather feedback from participants"
    }
  },
  "approvalResponses": {
    "label": "Approval requests",
    "CANCELED": {
      "dateLabel": "canceled on {{date}}"
    },
    "INITIAL": {
      "dateLabel": "sent on {{date}}"
    },
    "APPROVED": {
      "dateLabel": "approved on {{date}}"
    },
    "REJECTED": {
      "dateLabel": "rejected on {{date}}"
    }
  },
  "quotes": {
    "label": "Quotes",
    "INITIAL": {
      "sublabel": "sent to {{userLabel}}",
      "dateLabel": "{{date}}"
    },
    "SIGNED": {
      "sublabel": "signed by {{userLabel}}",
      "dateLabel": "{{date}}"
    },
    "CANCELED": {
      "sublabel": "rejected by {{userLabel}}",
      "dateLabel": "{{date}}"
    },
    "confirmationModal": {
      "delete": {
        "title": "Delete a quote",
        "message": "Are you sure you want to delete this quote?"
      }
    },
    "toasts": {
      "delete": {
        "error": "An error occurred while deleting the quote",
        "success": "Quote deleted"
      },
      "open": {
        "error": "An error occurred while opening the quote"
      }
    }
  },
  "invoices": {
    "label": "Invoices",
    "DRAFT": {
      "dateLabel": "available for download after the event"
    },
    "OPEN": {
      "dateLabel": "issue on {{date}}"
    },
    "PAID": {
      "dateLabel": "paid on {{date}}"
    }
  },
  "helpText": "Do you have questions or need to make a change to your booking? <intercom>Let us know here</intercom>",
  "viewComment": "View comment ->",
  "schedule": {
    "heading": "Event schedule"
  },
  "billingInfo": {
    "heading": "Billing information"
  },
  "invoicing": {
    "heading": "Invoicing"
  },
  "emptyStates": {
    "canceled": {
      "heading": "Booking canceled",
      "description": "Your booking request has been canceled."
    },
    "participants": {
      "heading": "No participants",
      "description": "You currently have no participants to your event."
    },
    "reviews": {
      "heading": "No reviews yet",
      "description": "Share the survey link below to the participants.\nAll submitted reviews will be displayed here."
    }
  },
  "toasts":{
    "fetchBudgets": {
      "error": "An error occurred while retrieving budgets"
    },
    "fetchTasks": {
      "error": "An error occurred while retrieving tasks"
    },
    "fetchTeams": {
      "error": "An error occurred while retrieving teams"
    },
    "inviteToPay": {
      "success": "Invitation to pay successfully sent"
    },
    "removeItem": {
      "error": "An error occurred while removing the item",
      "success": "Item removed"
    },
    "update": {
      "error": "An error occurred while updating your booking",
      "success": "Booking updated"
    }
  },
  "warnings": {
    "noEntities": {
      "message": "Please fill in the billing information which must appear on the invoice",
      "button": "Add billing info"
    }
  },
  "modals": {
    "addOrganizers": {
      "title": "Add an organizer",
      "users": {
        "label": "Select the users to add as organizer",
        "placeholder": "Search a user"
      },
      "inviteOrganizers": "Invite organizers",
      "toasts": {
          "success": "Organizers successfully added",
          "error": "An error occurred while adding organizers"
      }
    },
    "customItem": {
      "dateTime": {
        "label": "Primary choice of date & time",
        "flexible": {
          "label": "I'm flexible",
          "placeholder": "Please enter alternative dates and times for your event in case your primary choice is not available."
        }
      },
      "comment": {
        "label": "Event budget & details",
        "placeholder": "Please provide details about your event, including your budget, preferences, and any useful information or specific requirements.\n\nThe more info you give us, the better the proposal you receive!"
      },
      "submit": "Send my request",
      "toasts": {
        "success": "Request successfully sent",
        "error": "An error occurred while sending your request",
        "fetchUserBudgets": {
          "error": "An error occurred while retrieving your budgets"
        }
      }
    },
    "howtoCard": {
      "CUSTOM_REQUEST": {
        "description": "Let us know what you have in mind for your event, feel free to share places or vendors you like and include as many details as you can to receive the best proposals!",
        "step1": "Send your request of event via the form on the right",
        "step2": "Receive an email when the proposals and quotes from our providers are ready in your dashboard",
        "step3": "Choose the option that works best for your event",
        "title": "On demand event services"
      },
      "PARTNER_PLACE": {
        "description": "This partner's page is coming soon, send us a request so we can gather one or multiple quotes for you to get the best proposals from them!",
        "step1": "Send your request via the form on the right",
        "step2": "Receive an email when the proposals and quotes from the provider are ready in your dashboard",
        "step3": "Choose the option that works best for your event"
      },
      "PAY_VIA_JURNEE": {
        "description": "If you wish to manage your event autonomously and already have a quote, please upload it with the necessary information on the right so we can manage the approval & payment for you!",
        "step1": "Send the quote of your provider via this form",
        "step2": "We’ll reach out to the provider to ensure the quote is compliant with your company guidelines",
        "step3": "Receive an email when your event is ready for payment and confirm it on your dashboard",
        "title": "Pay via Jurnee"
      },
      "SPECIFIC_PARTNER": {
        "description": "If you already know which provider you would like to use, send us a request so we can gather one or multiple quotes for you to get the best proposals from them!",
        "step1": "Send your request of provider via the form on the right",
        "step2": "Receive an email when the proposals and quotes from the provider are ready in your dashboard",
        "step3": "Choose the option that works best for your event",
        "title": "Specific provider"
      }
    },
    "payViaJurnee": {
      "description": {
        "label": "Service description",
        "placeholder": "Ex: Catering, Diner restaurant, Transport…"
      },
      "partnerDocument": {
        "label": "Please add your quote"
      },
      "amount": {
        "label": "Total price including taxes"
      },
      "fees": {
        "label": "Fees and conversion rate",
        "description": "A payment fee of {{feeRate}}% will be applied.",
        "conversion": "A conversion rate {{sourceId}} => {{targetId}} of {{rate}} will be used.",
        "totalPrice": "The amount to pay will be <strong>{{total}}</strong>"
      },
      "submit": "Request payment",
      "toasts": {
        "success": "Payment request successfully sent",
        "error": "An error occurred while sending your payment request",
        "fetchUserBudgets": {
          "error": "An error occurred while retrieving your budgets"
        }
      }
    },
    "removeItem": {
      "title": "Remove schedule",
      "message": "Are you sure you want to remove <strong>{{name}}</strong> from the event?"
    },
    "cancelBooking": {
      "title": "Cancel booking",
      "message": "Are you sure you want to cancel <strong>{{name}}</strong> booking request?"
    },
    "eventConfirmed": {
      "heading": "Your event is confirmed"
    }
  },
  "budget": {
    "title": "Budget",
    "budgetIndividualCard": {
      "distributedBetween": "Budget distributed between",
      "individualBudget": "Individual budget",
      "status": "Status",
      "withinBudget": "All within budget",
      "outOfBudget": "{{count}} people out of budget"
    }
  },
  "organizers": {
    "addOrganizer": "Add an organizer",
    "table": {
      "dotMenu": {
        "remove": "Remove organizer",
        "removeConfirmation": {
          "title": "Remove organizer",
          "message": "Are you sure you want to remove <strong>{{organizer}}</strong> from the organizers?"
        }
      }
    },
    "toasts": {
      "removeOrganizer": {
        "success": "Organizer removed",
        "error": "An error occurred while removing the organizer"
      }
    }
  },
  "drawers": {
    "editBookingItem": {
      "title": "Edit",
      "date": {
        "label": "Preferred date"
      },
      "time": {
        "label": "Preferred time"
      },
      "comment": {
        "label": "Comment",
        "helperText": "Feel free to add any useful information",
        "placeholder": "Please make sure to specify ideally the location and any detail that you think is relevant. The more info you give us, the better the proposal you receive!"
      },
      "language": {
        "label": "Language"
      },
      "toasts": {
        "success": "Schedule update has been successfully sent",
        "error": "An error occurred while editing the schedule"
      }
    },
    "editBooking": {
      "header": "Edit booking",
      "form": {
        "team": {
          "label": "Team",
          "placeholder": "Select a team"
        },
        "budget": {
          "label": "Budget",
          "placeholder": "Select a budget"
        }
      }
    }
  },
  "priceDetails": {
    "subtotal": "Subtotal",
    "discount": "Discount",
    "vat": "VAT",
    "total": "Total",
    "currencyWarning": "You will be charged an amount of <strong>{{price}}</strong> with an exchange rate of <strong>{{rate}}</strong>"
  },
  "dotMenu": {
    "cancel": "Cancel booking",
    "edit": "Edit booking"
  },
  "processing": {
    "RECEIVED": "Request received",
    "CONTACTING": "Contacting partners",
    "WAITING": "Waiting for partners propositions",
    "CREATING": "Creating propositions"
  },
  "payViaJurnee": {
    "RECEIVED": "Request received",
    "CONTACTING": "Contacting partner",
    "WAITING": "Waiting for confirmation",
    "CREATING": "Finalizing pricing"
  },
  "status": {
    "AWAITING_APPROVAL": "pending approval",
    "AWAITING_QUOTE": "pending quote",
    "CANCELED": "canceled",
    "CONFIRMED": "confirmed",
    "CONFIRMING_AVAILABILITY": "confirming details",
    "DRAFT": "draft",
    "EXTERNAL_COSTS": "external costs",
    "FINISHED": "finished",
    "PROPOSITION_READY": "proposal ready",
    "PROCESSING": "prepping options"
  }
}